.profile {
    margin-top: 24px;
    font-size: 1.6rem;
    background: rgb(214, 214, 214);
    min-height: 500px;
    border-radius: 25px;
    text-align: center;
}
.profile .profile--label {
    margin-top: 12px;
    text-align: right;
}
.profile .profile--label-input {
    min-width: 300px;
    padding: 2px 4px;
}
