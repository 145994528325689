.advance_search_container {
    text-align: center;
    min-width: 200px;
    max-width: 430px;
    margin: auto;
    padding: 16px;
    border: 1px solid #333;
    border-radius: 25px;
    box-shadow: 4px 8px #888888;
}

.advance_search_container-input {
    width: 70%;
}

.advance_search_container-button {
    width: 50%;
    font-size: 1.5rem;
    font-weight: 600;
}
