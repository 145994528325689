.guest-name_short-time {
    position:  relative;
}
.guest-name_short-time_count {
    margin: auto;
    padding: 0 25px;
}

.guest-name_short-time_list {
    display: none;
    position: absolute;
    right: -40px;
    padding: 0 10px;
    right: 6px;
    top: 25px;
    z-index: 10;
}

.guest-name_short-time_count:hover .guest-name_short-time_list{
    display: block;
}

.guest-name_short-time:hover .guest-name_short-time_list{
    display: block;
}