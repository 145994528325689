@keyframes rainbow-animation {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
  }
  
.rainbow-text {
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(to right, red, orange, yellow, green, cyan, blue, violet);
    background-size: 200%; /* Kích thước lớn hơn để chuyển động mượt */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-animation 3s linear infinite; /* Chuyển động liên tục */
  }
  