.education {

}

.education-list {
    align-items: center;
    width: 18%;
    margin-left: 20px;
    border-right: 3px solid black
}

.education-list .education-list_item {
    width: 200px;
    font-size: 2rem;
    border: 2px solid #333;
    cursor: pointer;
    text-align: center;
    padding: 8px;
    margin: 8px;
    border-radius: 25px;
    box-shadow: 2px 5px #888888;
}


.education-list_item:hover {
    color: red;
    font-weight: 700;
}

.education-list_item-mini:hover{
    color: red;
    font-weight: 700;
}

.education-list .education-list_item-mini {
    width: 180px;
    font-size: 2rem;
    border: 2px solid #333;
    cursor: pointer;
    text-align: center;
    padding: 6px;
    margin: 8px 64px;
    border-radius: 25px;
    box-shadow: 2px 5px #888888;
}

.education-video {
    overflow-y: auto;
    width: '1220px';
    flex-wrap: wrap;
    height: 700px
}

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
    .education-list_item {
        background-color: cornflowerblue;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
    .education-list_item {
        background-color: cornflowerblue;
    }
}


/* Desktops and laptops ----------- */
@media only screen and (min-width: 900px) and (max-width: 1022px)  {
    /* Styles */
    .education-list  .education-list_item {
        font-size: 1.3rem;
        margin: 8px;
        width: 130px;
    }

    .education-list .education-list_item-mini  {
        font-size: 1.2rem;
        width: 115px;
        margin: 4px 16px;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1023px) and (max-width: 1223px)  {
    /* Styles */
    .education-list  .education-list_item {
        font-size: 1.5rem;
        width: 180px;
    }

    .education-list .education-list_item-mini  {
        margin: 8px 48px;
        font-size: 1.4rem;
        width: 135px;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1250px)  and (max-width: 1450px) {
    .education-list .education-list_item-mini  {
        margin: 8px 32px;
    }
}


/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
    /* Styles */
    .education-list  .education-list_item {
        font-size: 2.5rem;
        width: 280px;
    }

    .education-list .education-list_item-mini  {
        margin: 8px 48px;
        font-size: 2.rem;
        width: 235px;
    }
}
