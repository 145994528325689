.survey_online {
    font-size: 1.5rem;
}

.survey_online div h3 {
    border: 1px solid #666;
    margin: 0;
    padding: 4px;
}

.survey_online .survey_online-all_survey {
    width: 85%;
    overflow: scroll;
}

.survey_report_select .survey_report_select-item {
    max-width: 250px;
}

.survey_report_select .active {
    opacity: 1;
}

.survey-total-title .survey-total-title_month {
    padding: 4px;
    max-width: 100px;
    color: red;
}

.survey-total-title .survey-total-title_month .survey-total-title_month-text {
    font-size: 1.5rem;
}

.survey-total-title .survey-total-title_month .survey-total-title_month-number {
    font-weight: 600;
    font-size: 1.8rem;
}
.survey-total-title .survey-total-title_detail {
    padding: 4px;
}

.survey-total-title .survey-total-title_detail .survey-total-title_detail-week {
    margin: auto;
    padding: 4px;
    border-right: 4px solid #666;
}

.survey-total-title .survey-total-title_detail .survey-total-title_detail-content {
    padding: 0 4px;
}
.survey_online .survey_online-item_title {
    min-width: 250px;
}

.survey_online .survey_online-item {
    min-width: 72px;
    max-width: 72px;
}

.survey_online_guest_fb {
    position: relative;
    min-height: 60px;
    max-height: 60px;
    cursor: pointer;
}

.survey_online_guest_text {
    min-height: 50px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: yellow;
}

.survey_online_guest_fb-sub {
    position: absolute;
    align-items: center;
    display: none;
    background-color: yellow;
    z-index: 999;
    top: -150px;
    /* width: 130px; */
    padding: 8px;
    right: -30px;
    min-height: 130px;
    border-radius: 15px;
}

.survey_online_guest_fb-sub::before {
    /* arrow*/
    content: "";
    cursor: default;
    border-width: 14px 27px;
    border-style: solid;
    border-color: yellow transparent transparent transparent;
    position: absolute;
    right: 40px;
    top: 128px;
}

.survey_online .survey_online-item_title:nth-child(even) {
    background-color: #3333;
}

.survey_online .survey_online-item:nth-child(even) {
    background-color: #3333;
}

.survey_online_guest_fb:hover .survey_online_guest_fb-sub {
    display: block;
}

.survey_online_guest_text:hover .survey_online_guest_fb-sub {
    display: block;
}

.survey_online .survey_online-report {
    width: 60%;
    font-size: 2.8rem
}

.survey_online .survey_online-feedback {
    border-left: 1px solid #333;
     max-height: 800px;
     overflow-x: scroll;
     width: 40%
}