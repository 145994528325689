.calvat {
}

.calvat .calvat_item {
    min-width: 250px;
    border: 1px solid #333;
    height: 100%;
    padding: 20px;
    font-size: 1.5rem;
}

.bg_room {
    background: lightgreen;
    border-radius: 20px;
}

.bg_service {
    background: cornsilk;
    border-radius: 20px;
}
