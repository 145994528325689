.mini-bill {
    margin: auto;
    text-align: center;
    align-items: center;
}
.mini-bill .mini-bill__table {
    text-decoration: none;
    list-style: none;
    margin: 0;
    text-align: center;
    align-items: center;
}
.mini-bill .mini-bill__title li {
    font-weight: 700;
    margin: auto;
}

.mini-bill .mini-bill__details li {
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mini-bill__table li {
    border: 1px solid #333;
    min-width: 80px;
    padding: 5px;
    margin: auto;
}

li.mini-bill__details-name {
    overflow: hidden;
    text-overflow: ellipsis;
}
