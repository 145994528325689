.InputGroup {
    display: flex;
    height: 35px;
    width: 400px;
    padding-top: 4px;
    border: 1px solid red;
}

.InputGroup input[type="radio"] {
    visibility: hidden; /* 1 */
    height: 0; /* 2 */
    width: 0; /* 2 */
}

.InputGroup label {
    display: flex;
    flex: auto;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: #525252;
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    transition: color --transition-fast ease-out, background-color --transition-fast ease-in;
    user-select: none;
    margin-right: 8px;
}

.InputGroup label:last-of-type {
    margin-right: 0;
}

.InputGroup input[type="radio"]:checked + label {
    background-color: #4caf50;
    color: #232323;
}

.InputGroup input[type="radio"]:hover:not(:checked) + label {
    background-color: rgba(76, 175, 79, 50);
    color: #232323;
}

.debit_rp-bill {
    position: relative;
}

.debit_rp-bill-icon {
    font-size: 1.2rem;
    color: red;
    cursor: pointer;
}

.debit_rp-bill:hover .debit_rp-bill_sub {
    display: block;
}

.debit_rp-bill_sub {
    align-items: center;
    color: white;
    z-index: 999;
    padding: 4px;
    position: absolute;
    display: none;
    right: -108px;
    top: 28px;
    background-color: rgb(141, 137, 137);
    min-height: 64px;
    min-width: 180px;
    border-radius: 15px;
}

.debit_rp-bill_sub::before {
    /* arrow*/
    content: "";
    cursor: default;
    border-width: 12px 14px;
    border-style: solid;
    border-color: transparent rgb(141, 137, 137) transparent transparent;
    position: absolute;
    right: 114px;
    top: -10px;
}
