.rc-show-version_list {
    max-width: 250px;
    font-size: 1.5rem;
    padding: 0;
    background-color: #999;
    margin-left: 60%;
    list-style: none;
    border-radius: 10px;
    display: block;
    position: relative;
    margin-top: 14px;
}

.rc-show-version_list:after {
    z-index: 999;
    content: "";
    cursor: default;
    border-width: 18px 27px;
    border-style: solid;
    border-color: transparent transparent #999 transparent;
    position: absolute;
    right: 15px;
    top: -30px;
    cursor: pointer;
}

.rc-show-version_item {
    width: 100%;
    border-bottom: 1px solid #333;
    padding: 4px;
    align-items: center;
    margin: auto;
    cursor: pointer;
    color: white;

    text-underline-offset: true;
}

.rc-show-version_item .rc-show-version_item-link {
    color: white;
    font-size: 1.5rem;
}

.rc-show-version_item-icon {
    display: block;
    color: var(--primary-color);
    font-size: 2rem;
}

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
    /* Styles */
    .rc-show-version_list {
        margin-left: 54%;
    }
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
    /* Styles */
    .rc-show-version_list {
        margin-left: 47%;
    }
}
