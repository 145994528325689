.mini-bill {
    font-size: 1.5rem
}

.mini-bill th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }

  .mini-bill table, td, th {  
    border: 1px solid #ddd;
  }
  
  .mini-bill table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .mini-bill th, td {
    padding: 15px;
    font-size: 1rem;
  }

  .acc_modal_style {

  }

  .acc_modal_style-title {
    font-size: 1.4rem;
    text-align: center;
    background-color: yellow;
    font-weight: bold
  }

  .acc_modal_style-menu label  {
    cursor: pointer;
  }