.color-box {
    /* $box-size: 200px; */
    /* display: inline-block;
    width: $box-size;
    height: $box-size;
    border-radius: 8px;

    transition: all 0.35d ease-in-out 0s;
    cursor: pointer; */
}

.nav-item {
    background-color: #482979;
    border: 1px solid white;
    font-size: 1.4rem;
    border-radius: 5px;
    padding: 4px 8px;
    opacity: 0.6;
}

.nav-item.active {
    opacity: 1;
}
.nav-link {
    color: white;
}

.nav-fix-top {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    outline: none;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
    cursor: pointer;
    overflow: initial;
}

/* NAV SUB */
.nav-sub {
    margin-left: 123px;
}

.nav-item-sub {
    background-color: #c1a0f7;
    border: 1px solid white;
    font-size: 1.4rem;
    border-radius: 5px;
    padding: 4px 8px;
}

.nav-link-sub {
    color: black;
}

.nav-bar__asset {
    font-size: 1.5rem;
    position: relative;
    right: 0;
}

.nav-bar__icon {
    display: block;
    font-size: 2.4rem;
    color: var(--primary-color);
}

.nav-bar__asset-notif {
    padding: 8px;
    border-radius: 10px;
}

.nav-bar__asset-notif:hover {
    background-color: rgb(230, 227, 227);
}

.nav-bar__asset_I:hover {
    cursor: default;
}

.nav-bar__asset-user {
    padding: 4px;
    border-radius: 10px;
}

.nav-bar__asset-user:hover {
    background-color: rgb(230, 227, 227);
}
.nav-bar__asset-user:hover .nav-bar__asset-user__click {
    display: block;
}

.nav-bar__asset-user__click {
    z-index: 999;
    display: none;
    position: absolute;
    top: 45px;
    right: 0px;
    min-width: 160px;
    background: var(--primary-blur-color);
    border-radius: 25px;
    text-align: right;
}

.nav-bar__asset-user__click::before {
    /* arrow*/
    z-index: 999;
    content: "";
    cursor: default;
    border-width: 18px 27px;
    border-style: solid;
    border-color: transparent transparent var(--primary-blur-color) transparent;
    position: absolute;
    right: 15px;
    top: -35px;
}

.nav-bar__asset-user__click::after {
    /* road support*/
    content: "";
    display: block;
    position: absolute;
    top: -18px;
    right: 0;
    width: 100%;
    height: 19px;
    cursor: default;
}

.nav-bar__asset-user__menu {
    list-style: none;
    padding: 3px 0;
    text-decoration: none;
}

.nav-bar__asset-user__menu .nav-bar__asset-user__item {
    width: 100%;
    padding: 4px 24px;
}

.nav-bar__asset-user__menu .nav-bar__asset-user__item:last-child {
    border-top: 1px solid #666;
}

.nav-bar__asset-user__item .nav-bar__asset-user__text {
    text-decoration: none;
}
.nav-bar__asset-user__item:hover {
    background-color: white;
    text-decoration: none;
}

.nav-bar__asset-notif-count {
    position: absolute;
    top: 0px;
    right: 82%;
    max-height: 25px;
    color: white;
    align-items: center;
    background-color: crimson;
    text-align: center;
    padding: 0px 4px;
    border-radius: 25%;
}

#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}
