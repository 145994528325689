.arrow-right:after {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-right: 24px solid #8080803b;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    vertical-align: middle;
  }

/* .modal-nf div {
    font-size: 2rem;
    padding: 8px 0px;
}

.modal-nf .modal-nf_title {
    font-size: 4rem;
    font-weight: 600;
}
   */
@media print {
    @page {
        size: A4; /* DIN A4 standard, Europe */
        /* margin: 4px; */
      }

    html, body {
    /* width: 210mm; */
    /* height: 297mm; */
    /* height: 282mm; */
    /* font-size: 11px;
    background: #FFF;
    overflow: visible; */
    }
    
    body {
        /* padding-top: 15mm; */
        margin: 100;
        padding: 0;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }

}