.acc-chart_tablet_title-item {
    font-size:1.8rem;
    padding: 8px;
    width:150px;
}

.acc-chart_tablet_sale-item{
    font-size:1.6rem;
    padding: 8px;
    width:150px;
}

.acc-chart_tablet_sale-input {
    font-size:1.6rem;
}

.acc-chart_tablet_sale-history{
    color: blue;
    cursor: pointer;
    text-decoration-line: underline;
}

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
    .acc-chart_tablet_sale-input {
        font-size:1.3rem;
        width:100%;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
    /* Styles */
    .acc-chart_tablet_sale-input {
        font-size:1.3rem;
        width:100%;
    }
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
    /* Styles */
    .acc-chart_tablet_sale-input {
        font-size:1.8rem;
    }
}