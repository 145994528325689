.dropdown-item {
    cursor: pointer;
}

.dropdown {
}

.dropdown .dropdown-icon {
    align: center;
    margin: auto;
    cursor: pointer;
    opacity: 0.1;
}

.dropdown-icon:hover {
    opacity: 1;
}

.namesub__btn {
    font-size: 1.1rem;
    width: 60%;
    flex-grow: 4;
    background: none;
    border: none;
}

.dropdown .dropdown-toggle-text {
    flex-grow: 2;
    width: 20%;
    background: none;
    border: none;
    margin-right: 12px;
    font-size: 1rem;
    opacity: 0.1;
}

.dropdown .dropdown-toggle-text:hover {
    opacity: 1;
}

.dropdown .sub_td2-option {
    flex-grow: 1;
}
