.dropdown .dropdown-menu {
    background-color: rgb(207, 207, 207);
}

.dropdown-item {
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.dropdown-item__input {
    width: 50%;
    border-radius: 10px;
    cursor: pointer;
}

.dropdown-toggle {
    width: 100%;
}

.redcolor {
    color: red;
    border: none;
    padding: 0;
    margin: 0;
    align-items: flex-start;
}

.namesub__btn.dropdown-menu-input {
    width: 100%;
    margin: 2px;
    padding: 2px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
}

.dropdown-item__input-paid {
    width: 70px;
    margin-left: 16px;
}
