.not_rp {
    border: 1px solid #333;
    border-radius: 20px;
    margin-top: 5%;
    width: 75%;
    box-shadow: 9px 9px #666;
    text-align: center;
}

.not_rp .not_rp-img {
    width: 40%;
}
