.hidden-border {
    border: 0;
}

td .hidden-border.w-80 {
    max-width: 80%;
    min-width: 80%;
}

.bg_test {
    background: rgb(209, 143, 143);
}

.bg_test:hover {
    color: red;
}

.expected-day_item {
    position: relative;
}

.expected-day_item:hover {
    border: 0.5px solid red;
}
.expected-day_item:hover .expected-day_item-support {
    display: block;
}
.expected-day_item:hover .expected-day_item-sub {
    display: block;
}

.expected-day_item-sub {
    align-items: center;
    /* height: 100px; */
    color: white;
    z-index: 999;
    padding: 8px;
    position: absolute;
    display: none;
    /* right: -50px;
    top: -1px; */
    background-color: rgb(141, 137, 137);
    border-radius: 11px;
    inset: 27.6px auto auto -36px;
}

.expected-day_item-sub::before {
    /* arrow*/
    content: "";
    cursor: default;
    border-width: 8px 27px;
    border-style: solid;
    border-color: transparent transparent rgb(141, 137, 137) transparent;
    position: absolute;
    right: 58px;
    top: -11px;
}

.expected-day_item-support {
    align-items: center;
    min-width: 116px;
    color: white;
    z-index: 999;
    padding: 6px;
    position: absolute;
    display: none;
    background-color: rgb(141, 137, 137);
    border-radius: 11px;
    inset: 31.6px auto auto -31px;
}

.expected-day_item-sub .expected-day_item-support_button {
    padding: 0 15px;
    align-items: center;
    text-align: center;
    background: white;
    width: 100%;
}
.expected-day_item-support .expected-day_item-support_button {
    padding: 0 15px;
    align-items: center;
    text-align: center;
    background: white;
    width: 100%;
}

.expected-day_item-support::before {
    /* arrow*/
    content: "";
    cursor: default;
    border-width: 8px 27px;
    border-style: solid;
    border-color: transparent transparent rgb(141, 137, 137) transparent;
    position: absolute;
    right: 27%;
    top: -14px;
}
