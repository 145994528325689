.btn.btn__add {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.btn__add_text {
    font-size: 1.4rem;
    font-weight: 600;
}

.btn__add_icon {
    display: block;
    font-size: 2rem;
    color: green;
    padding-left: 4px;
}
