@media print 
{
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin: 16px;
    }
    html, body {
        /* width: 210mm; */
        /* height: 297mm; */
        /* height: 282mm; */
        /* font-size: 11px;
        background: #FFF;
        overflow: visible; */
    }
    body {
        /* padding-top: 15mm; */
    }

    .w100 {
        width: 100%;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }
    
}