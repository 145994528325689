.notfound__img {
    position: relative;
}

.notfound__btn {
    position: absolute;
    top: 603px;
    left: 41%;
    color: rgb(255, 255, 255);
    font-size: 2rem;
    border-radius: 20px;
    background: rgb(72, 41, 121);
    padding: 16px;
}

.notfound__btn:hover {
    text-decoration: none;
    color: rgb(255, 255, 255);
    opacity: 0.7;
}
