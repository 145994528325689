.debit__search .debit__search_text {
    font-size: 1.4rem;
    align-items: center;
    padding-top: auto;
    padding-right: 8px;
    font-weight: 600;
}

.debit__search .debit__search_input {
    width: 200px;
}

h1.row_debit__freeze {
    z-index: 10;
    background-color: white;
    font-size: 2.5rem;
    font-weight: 600;
    position: sticky;
    top: 40px;
    overflow: hidden;
}

.mt5px {
    margin-top: 5px;
}

.btn.btn-search {
    min-width: 80px;
    font-size: 1.2rem;
    border: 1px solid #333;
    border-radius: 30px;
}

.btn-search:hover .btn__add_icon {
    cursor: pointer;
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.rc_debit .loading {
    background-image: url("https://i.gifer.com/YCZH.gif");
    width: 200px;
    height: 100px;
}
