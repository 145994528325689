.reservation-container .table-container {
    /* width: 100%; */
    margin: 20px auto;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
  }
  
.reservation-container table {
  border-collapse: collapse;
  width: 60%;
}

.table-container th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid black;
}

.table-container th {
  background-color: yellow;
  font-size: 1.5rem;
  border: 1px solid black;
}

.table-container td {
  /* background-color: #f2f2f2; */
  font-size: 1.5rem;
  border: 1px solid black;
}

td.bg-yellow {
  background-color: yellow;
}

td.bg-green_yellow {
  background-color: greenyellow;
}

.reservation-container .btn-ja {
  width: 35px;
  margin: auto;
  height: 30px;
  padding: 0px;
  border: none;
  background: url('https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg') center center / contain no-repeat;
  background-color: yellow;
}

.reservation-container .btn-en {
  width: 35px;
  margin: auto;
  height: 30px;
  padding: 0px;
  border: none;
  background: url('https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb.svg') center center / contain no-repeat;
  background-color: yellow;
}

.reservation-container .btn-vie {
  width: 35px;
  margin: auto;
  height: 30px;
  border: n0ne;
  background: url('https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/vn.svg') center center / contain no-repeat;
  background-color: yellow;
}

.reservation-container .btn-kor {
  width: 35px;
  margin: auto;
  height: 30px;
  padding: 0px;
  border: none;
  background: url('https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg') center center / contain no-repeat;
  background-color: yellow;
}