.rp td,
.rp th {
    border: 1px solid #ddd;
    font-size: 1.2rem;
    text-align: center;
    height: 30px;
}

.rp tr:nth-child(even) {
    background-color: #f2f2f2;
}

.rp tr:hover {
    background-color: #ddd;
}

.rp tr:nth-child(2) {
    padding-top: 1000px;
}
.rp th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #4caf50;
    color: white;
}

.table-scoll .rp_row {
    padding-top: 50px;
}

.rp.rp__table {
    margin-top: 96px;
}

.rp__tr {
    position: sticky;
    top: 110px ;
    z-index: 11 ;
}
.rp__hi {
    min-width: 120px;
    max-width: 120px;
}

.rp__delete {
    position: relative;
    overflow: visible;
}

.sub_td-track-icon {
    color: #4caf50;
    font-size: 1.5rem;
    display: none;
    transition: transform 0.2s; /* Animation */
    cursor: pointer;
}

.rp__hi:hover .sub_td-track-icon {
    display: block;
}

.sub_td-track-icon:hover {
    cursor: pointer;
    color: rgb(241, 75, 75);
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.rp_out-message {
    display: block;
    position: absolute;
    top: 65%;
    color: red;
    font-size: 1.1rem;
    padding: 1px;
    border-radius: 20px;
    border: 1px solid #333;
    background-color: white;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.rp_out-message::after {
    display: block;
    content: "";
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: -12px;
    color: unset;
    background: none;
    color: none;
    border-width: 6px 8px;
    border-style: solid;
    border-color: transparent transparent black transparent !important;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.btn.btn__add {
    display: block;
    border: 1px solid #4caf50;
    border-radius: 50px;
    margin-left: 50%;
    text-align: center;
    cursor: pointer;
}

.btn__add-icon {
    padding: 2px;
    font-weight: 300;
    font-size: 2rem;
    color: #4caf50;
}

.sub_td1 {
    margin: 0 0;
}

.sub_td4,
.sub_td3,
.sub_td2 {
    font-size: 1.2rem;
    border-top: 1px solid #ddd;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.sub_td2 {
    z-index: 11;
}

.sub_td2-btn {
    border: unset;
    background: unset;
    min-width: 150px;
    text-align: start;
    font-size: 1.3rem;
}

.sub_td1-option {
    position: absolute;
    font-size: 1.5rem;
    color: red;
    right: 0;
    display: block;
    top: 25%;
    width: 5%;
    opacity: 0.01;
    cursor: pointer;
}

.icon-plus {
    color: red;
}
.dropdown-item {
    font-size: 1.3rem;
}

.sub_td1-option:hover {
    opacity: 1;
}

.sub_td1:hover .sub_td1-option {
    display: block;
}

.sub_td2-text,
.sub_td3-text {
    min-width: 100px;
}

.sub_td2-option {
    display: flex;
    text-align: left;
}

.sub_td2-option-minus,
.sub_td2-option-plus {
    opacity: 0.5;
    margin-left: 4px;
}

.sub_td2-option-minus:hover,
.sub_td2-option-plus:hover {
    opacity: 1;
}

.btn:hover {
    opacity: 0.5;
}

.btn.btn_send {
    font-size: 1.6rem;
    padding: 8px 2px;
    border: 1px solid #4caf50;
}

.btn_send .btn_icon-send {
    padding-left: 8px;
    color: #4caf50;
}

.btn.btn_cancel {
    font-size: 1.6rem;
    border: 1px solid red;
}

.btn_cancel .btn_icon-cancel {
    padding-left: 8px;
    color: red;
}

.footer {
    text-align: center;
    align-items: center;
    margin: 0px 20%;
}

.sub_td1-input-m {
    min-width: 40px;
    max-width: 40px;
    padding-left: 4px;
    border: unset;
    align-items: center;
    background: unset;
}

.sub_td1-input-l {
    min-width: 50px;
    max-width: 50px;
    padding-left: 4px;
    border: unset;
    align-items: center;
    background: unset;
}

.sub_td1-input-xl {
    min-width: 65px;
    max-width: 65px;
    padding-left: 4px;
    border: unset;
    align-items: center;
    background: unset;
}

.sub_td1-input-xxl {
    min-width: 120px;
    max-width: 120px;
    padding-left: 4px;
    border: unset;
    align-items: center;
    background: unset;
}

.sub_td1-input-name {
    white-space: wrap;
    overflow: inherit;
    text-overflow: clip;
    min-width: 100%;
    max-width: 100%;
}

.sub_td1-input-hi {
    min-width: 90px;
    max-width: 90px;
}

.sub_td1-input-checkout,
.sub_td1-input-checkin {
    min-width: 100px;
    max-width: 100px;
}

/* Footer */
.total {
    /* margin-left: 645px; */
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.total__title,
.total__vnd,
.total__vcb,
.total__bidv {
    min-width: 50px;
    max-width: 50px;
}

.total__sign {
    min-width: 90px;
    max-width: 90px;
}

.total .tr {
    font-size: 1rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    padding: 0;
    margin: 0;
    border-width: thin;
}

.footer-btn {
    padding-top: 24px;
}
