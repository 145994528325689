.random {
    font-size: 2rem;
}
.random .member {
    background-color: yellow;
    min-height: 500px;
}

.random .list_random {
    /* background-color: blue; */
    min-height: 500px;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 4px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
    font-size: 1.2rem;
    text-align: center;
}
