.table-room {
    position: relative;
}

.table-room .table-room_list {
    position: absolute;
    top: 25px;
    right: 0px;
    min-width: 103px;
    background: azure;
    cursor: pointer;
    list-style: none;
    padding: 4px 8px;
}

.table-room .table-room_list .table-room_item:hover {
    background: yellow;
}
