.dropdown.select_branch {
    background-color: #c1a0f7;
    align-items: center;
    color: black;
    font-size: 1.4rem;
    border-radius: 5px;
    margin: 0 4px;
}

.dropdown.select_branch .btn-group {
    font-size: 1.4rem;
}

.dropdown.select_branch .dropdown-toggle-text {
    color: black;
    opacity: 1;
    font-size: 1.4rem;
    font-weight: 400;
    width: 100%;
    height: 100%;
    align-items: center;
    min-width: 120px;
    border: unset;
}
