.expected-day {
    font-size: 1.4rem;
    min-width: 65px;
    max-width:65px;
    padding: 2px;
    background-color: unset;
    border: 1px solid rgb(0, 0, 0);
    font-weight: 600;
    text-align: center;
}

.capacity-header {
    font-size: 1.2rem;
    margin: 10px 0 0 0;
}

.capacity-header .capacity-header_item {
    border: 1px solid rgb(0, 0, 0);
    text-align: center;
    align-items: center;
}

.capacity-header .capacity-header_item .capacity-header_item-capacity {
    padding: 0;
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
}

.capacity-header .capacity-header_item .capacity-header_item-capacity:hover {
    color: var(--primary-color);
}
