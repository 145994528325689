.search_input {
    font-size: 1.3rem;
    border: 1px solid red;
}

.accsum_checkbox {
    font-size: 1.2rem;
}

.fix_date {
    position: sticky;
    top: 42px;
    left: 0px;
    /* border: 0.5px solid rgb(195, 194, 194); */
}

.fix_HI {
    position: sticky;
    top: 42px;
    left: 96px; 
    /* border: 0.5px solid rgb(195, 194, 194); */
}

.fix_SI {
    position: sticky;
    top: 42px;
    left: 192px;
    /* border: 2px solid red; */
}

.fix_date_th {
    position: sticky;
    left: 0px;
    top: 42px;
    background: aliceblue;
    z-index: 10 !important;
}

.fix_HI_th {
    position: sticky;
    left: 96px;
    top: 42px;
    background: aliceblue;
    z-index: 10 !important;
    border: 1px solid red;
}

.fix_SI_th {
    position: sticky;
    left: 192px;
    top: 42px;
    background: aliceblue;
    z-index: 10 !important;
    border: 2px solid red;
}

