.mng_rp-bill {
    position: relative;
}

.mng_rp-bill-icon {
    font-size: 1.2rem;
    color: red;
    cursor: pointer;
}

.mng_rp-bill:hover .mng_rp-bill_sub {
    display: block;
}

.mng_rp-bill_sub {
    align-items: center;
    color: white;
    z-index: 999;
    padding: 4px;
    position: absolute;
    display: none;
    right: -80px;
    top: -16px;
    background-color: rgb(141, 137, 137);
    min-height: 50px;
    min-width: 100px;
    border-radius: 15px;
}

.mng_rp-bill_sub::before {
    /* arrow*/
    content: "";
    cursor: default;
    border-width: 14px 27px;
    border-style: solid;
    border-color: transparent rgb(141, 137, 137) transparent transparent;
    position: absolute;
    right: 89px;
    top: 18px;
}
