.cal_rc_wallet-content {
    font-size: 1.5rem;
}
.cal_rc_wallet-content_item {
    min-width: 100px;
    background: greenyellow;
    padding: 9px;
}

.cal_rc_wallet-content_input {
    max-width: 70px;
    padding: 0;
    margin: 0;
    max-height: 50px;
}

.cal_rc_wallet table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    font-size: 1.5rem;
}

.cal_rc_wallet table tr th {
    font-size: 1.5rem;
}

.cal_rc_wallet td,
.cal_rc_wallet th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.cal_other_wallet-content_item {
    min-width: 80px;
}
