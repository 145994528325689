.item-text {
    background-color: yellow;
    padding: 4px;
    align-items: center;
    font-weight: 600;
    font-size: 1.3rem;
    border: 1px solid #333;
}

.item-text-sub {
    font-size: 1.3rem;
    background-color: burlywood;
    padding: 8px;
    border: 1px solid #333;
}

.item-center {
    padding-top: 34px;
   
}

.welcome {
    display: flex;
    min-height: 530px;
    display: flex;
    justify-content: space-around;
    background-color: white;
    border: 1px solid rgb(138, 135, 135);
    border-radius: 12px;
    box-shadow: 9px 9px #666;

}

.welcome-title {
    font-size: 1.3rem;
}

.welcome-text {
    font-size: 3rem;
    color: var(--primary-color);
    font-weight: 800;
    margin-left: 10px;
    box-shadow: 2px 2px rgb(218, 218, 218);
}

.welcome-img {
    width: 100%;
}

/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1023px) and (max-width: 1223px)  {
    /* Styles */
    .welcome-text {
     
    }

    .welcome-img {
        width: 250px;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
    /* Styles */
    .welcome-text {
    
    }

    .welcome {
       
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-device-width: 1250px)  and (max-width: 1450px) {
    /* Styles */
    .welcome-text {
        margin-left: 85px;
    }

    .welcome-img {
        width: 650px;
    }
}


/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
    /* Styles */
     .welcome-text {

    }

    .welcome {

    }
}

