.btn.btn-modal__close {
    font-size: 1.5rem;
    min-width: 80px;
}

.btn.btn-modal__ok {
    font-size: 1.5rem;
    min-width: 80px;
}

.modal-body__content-input {
    font-size: 1.5rem;
    width: 120%;
}

.modal-body__icon-edit {
    color: green;
    margin: 0 4px;
}

.modal-body__icon-info {
    font-size: 3rem;
    color: green;
    margin: 0 4px;
}
