.modal-footer-btn5 {
    flex: 1;
}

.modal-footer-btn5 textarea {
    width: 100%;
    font-size: 1.2rem;
}

.modal-footer-btn5 .button-area {
    text-align: right;
}
