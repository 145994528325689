.glist-title {
    padding: 2px;
    margin: unset;
    border: 2px solid black;
}

.glist-day {
    padding: 2px;
    margin: unset;
    border: 2px solid black;
}

.glist-count {
    font-size:1.8rem;
    text-align: left;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 600;
}

.glist-room-show {
    width: 100%;
    text-align: left;
    padding:0 8px;
    min-height: 70px;
    overflow-wrap: anywhere;
    border: 1px solid black;
    font-size:2.2rem;
    font-weight: 600;
}

.glist-room-show_table-item {
    font-size:1.2rem;
}

.glist-room-show_table-item-check-in {
    font-size:2rem; 
    height: 32px;
}

.glist-room_note {
    width: 70px;
    font-size:1.8rem;
}

.glist-room-show_table-item-check-in_input {
    font-size:1.7rem; 
}