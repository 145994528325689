.man-show-data-list {
    position: relative;
    font-size: 1.5rem;
    padding: 8px;
    /* margin-left: 60%; */
    list-style: none;
    border-radius: 10px;
    display: block;
    margin-left: 120px;
}

.man-show-data-list .man-show-data-list_item {
    border: 1px solid black;
    margin: 0;
    padding: 4px;
    min-width: 50px;
    /* background-color: #999; */
    min-height: 32px;
}
