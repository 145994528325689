.manager-user {

}

.manager-user .manager-user-menu {
    width: 15%;
}

.manager-user_glist-note {
    text-align: left;
    padding: 2px 8px;
    font-size: 1.4rem;
}

.manager-user_menu-list .manager-user_menu-item {
    border: 1px solid black;
    font-size: 1.8rem;
    padding: 4px;
    width: 190px;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
}

.manager-user_menu-item.select {
    background-color: yellow;
}

.manager-user_glist-content {
     background-color: rgb(153, 153, 153,0.3);
     height:400px;
     max-height: 550px;
     overflow-y: auto;
}

.manager-user_branch-title {
    font-size:2rem;
    border: 1px solid black;
    min-width: 300px;
    
}

.manager-user_branch-item {
    font-size:1.6rem;
    border: 1px solid black;
    width: 500px;
}