.nav-bar__asset-notif:hover .notifi {
    display: block;
}

.notifi {
    display: none;
    z-index: 999;
    position: absolute;
    top: 30px;
    right: 0;
    left: -255px;
    min-height: 255px;
    max-height: 500px;
    width: 300px;
    background: var(--primary-pale-color);
    margin: 8px;
    border-radius: 10px;
    overflow: scroll;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    box-shadow: 2px 2px#666;
}

.notifi::before {
    /* arrow*/
    z-index: 999;
    content: "";
    cursor: default;
    border-width: 18px 27px;
    border-style: solid;
    border-color: transparent transparent var(--primary-blur-color) transparent;
    position: absolute;
    right: 0px;
    top: -35px;
}

.notifi::after {
    /* road support*/
    z-index: 999;
    content: "";
    display: block;
    position: absolute;
    top: -18px;
    right: 0;
    width: 100%;
    height: 19px;
    cursor: default;
    background: red;
}

.notifi__title {
    text-align: center;
    color: white;
    align-items: center;
}

.notifi__list {
    text-emphasis: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.notifi__item {
    padding: 4px 4px;
    margin: 4px 8px;
    background: white;
    border-radius: 10px;
}

.notifi__item:hover {
    font-size: 1.5rem;
    box-shadow: 2px 2px red;
}

.notifi__item .notifi__item--title {
    font-weight: 600;
    border-bottom: 1px solid #666;
}

.notifi__not_mes {
    text-align: center;
}

.notifi__item-not_message {
    color: white;
    text-align: center;
}

.notifi__item-img {
    width: 80%;
}
