.globalloading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
}

.globalloading-icon {
    width: 3%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100;
}

.globalloading-text {
    color: white;
    /* width: 5%; */
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100;
}
