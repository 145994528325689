.survey_online-item-container{
    position: relative
}

.survey_online-item-sub {
     position: absolute;
     display: none;
     background-color: yellow;
     top: -20;
     padding:8;
     right: 4;
}

.survey_online-item-container:hover .survey_online-item-sub{
    display: block;
}

.survey_online-item_title-infor_arrow {
    position: absolute; 
}

.survey_online-item_title-infor_text:after {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-right: 14px solid blue;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    vertical-align: middle;
}

.survey_online-item_title-infor_text {
    position: absolute; 
    z-index: 99;
    font-size: 1.3rem;
    background:blue;
    color:white;
    width: 160px;
    left: 190px;
    top: 113px;
    padding: 8px;
}
.survey_online-item_title-infor_text {
    display: none;
}

.survey_online-item_title-infor_arrow {
    display: none;
}

.survey_online-item_title-infor:hover .survey_online-item_title-infor_text {
    display: block;
}

.survey_online-item_title-infor:hover .survey_online-item_title-infor_arrow {
    display: block;
}