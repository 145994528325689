.btn.btn__add {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.btn__add_text {
    font-size: 1.4rem;
    font-weight: 600;
}

.btn__add_icon {
    display: block;
    font-size: 2rem;
    color: green;
    padding-left: 4px;
}

.btn_load_data {
    text-emphasis: none;
    text-decoration: none;
    color: white;
    min-width: 80px;
    font-size: 1.2rem;
    padding: 8px;
    border-radius: 10px;
    display: block;
}

.btn_load_data:hover {
    text-decoration: none;
    color: var(--primary-color);
}

.btn.btn__add {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.btn__add_text {
    font-size: 1.4rem;
    font-weight: 600;
}

.btn__add_icon {
    display: block;
    font-size: 2rem;
    color: green;
    padding-left: 4px;
}

.tableFixHead {
    overflow: auto;
    height: 100px;
}

.tableFixHead thead th {
    position: fixed;
    top: 0;
    z-index: 1;
}

h1.row_freeze {
    text-align: center;
    align-items: center;
    margin: 0 20%;
    min-width: 780px;
    z-index: 99;
    background-color: white;
    font-size: 2.5rem;
    font-weight: 600;
    position: fixed;
    top: 40px;
    overflow: hidden;
}
.nav-item.dropdown {
    z-index: 999;
    color: black;
}

@media print {
    #pager,
    form,
    .no-print {
        display: none !important;
        height: 0;
    }

    .no-print,
    .no-print * {
        display: none !important;
        height: 0;
    }

    .d-print-none {
        display: none !important;
    }
}
