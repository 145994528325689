.search_input {
    font-size: 1.3rem;
}

.rp td,
.rp th {
    border: 0.5px solid rgb(195, 194, 194);
    font-size: 1.2rem;
    text-align: center;
    /* height: 50px; */
}

.rp th {
    /* padding-top: 8px;
    padding-bottom: 8px; */
    text-align: center;
    background-color: #4caf50;
    color: white;
}

.rp__paid,
.rp__sum_credit {
    min-width: 100px;
    max-width: 100px;
}

.table-scoll .rp_row {
    padding-top: 50px;
}

.rp__select {
    min-width: 40px;
    max-width: 40px;
}

.rp__hi {
    min-width: 100px;
    max-width: 100px;
}

.rp__HI {
    min-width: 100px;
    max-width: 100px;
    /* min-width: 80px;
    max-width: 80px; */
}

.rp__in-icon {
    cursor: pointer;
    color: rgba(15, 15, 15, 0.5);
    font-size: 1.5rem;
}

.rp__in-icon.active {
    color: white;
}

.rp__SI {
    min-width: 80px;
    max-width: 80px;
}

.rp__name {
    min-width: 350px;
    max-width: 350px;
}

tr .rp__name {
    padding-left: 10px;
    padding-right: 8px;
    text-align: left !important;
}

.input__name textarea {
    width: 100%;
    height: 23px;
    border: none;
}

.rp .input__name textarea:hover {
    background-color: rgb(255, 164, 255);
}

.rp__room {
    min-width: 70px;
    max-width: 70px;
}

.rp__room_rate {
    min-width: 60px;
    max-width: 60px;
}

.rp__price {
    min-width: 60px;
    max-width: 60px;
}

.rp__r {
    min-width: 60px;
    max-width: 60px;
}

.rp__in {
    min-width: 120px;
    max-width: 120px;
}

.rp__checkin {
    /* min-width: 120px;
    max-width: 120px; */
    min-width: 75px;
    max-width: 75px;
}

.rp__checkout {
    /* min-width: 120px;
    max-width: 120px; */
    min-width: 75px;
    max-width: 75px;
}

.rp__import_to {
    /* min-width: 120px;
    max-width: 120px; */
    min-width: 75px;
    max-width: 75px;
}

.rp__remind {
    min-width: 75px;
    max-width: 75px;
}
.rp_out-date,
.rp_in-date {
    width: 150px;
    border: 0px;
}

.rp__action {
    min-width: 140px;
    max-width: 140px;
}

.rp__out {
    position: relative;
    min-width: 120px;
    max-width: 120px;
}

.rp__vnd {
    min-width: 90px;
    max-width: 90px;
}

.rp__usd {
    min-width: 60px;
    max-width: 60px;
}

.rp__yen {
    min-width: 60px;
    max-width: 60px;
}

.rp__vcb {
    min-width: 90px;
    max-width: 90px;
}

.rp__other-vcb {
    min-width: 90px;
    max-width: 90px;
}

.rp__vcb_other {
    min-width: 120px;
    max-width: 120px;
}

.rp__exp {
    min-width: 170px;
    max-width: 170px;
}

.rp__exp textarea {
    width: 100%;
    height: 23px;
    border: none;
}

.rp__exp-name {
    min-width: 150px;
    max-width: 150px;
}

.rp input:hover {
    background-color: rgb(255, 164, 255);
}

.rp__exp-price {
    min-width: 120px;
    max-width: 120px;
}

.rp__exp_rate {
    min-width: 120px;
    max-width: 120px;
}
.rp__note {
    word-wrap: break-word;
    min-width: 200px;
}

.rp .table-room {
    position: relative;
}

.rp .table-room .table-room_list {
    position: absolute;
    top: 23px;
    padding: 0;
    right: -18px;
    list-style: none;
    background-color: azure;
    cursor: pointer;
    z-index: 99;
}

.rp .table-room .table-room_list .table-room_item {
    width: 100%;
    min-width: 70px;
    text-align: center;
}

.rp .table-room .table-room_list .table-room_item:hover {
    background-color: rgb(255, 164, 255);
}

.rp .table-room .table-room_error {
    color: red;
    font-size: 1rem;
}

/* .rp__note:active {
    width: auto;
}

.rp__note:focus {
    min-width: 250px;
} */

.rp .rp__size {
    padding: 0;
}

.rp__size-405 {
    min-width: 409px;
    max-width: 409px;
}
.rp .rp__size-xs {
    min-width: 45px;
    max-width: 45px;
    overflow-wrap: break-word;
}

.rp__size-110 {
    min-width: 190px;
    max-width: 190px;
}
.rp .rp__size-s {
    min-width: 60px;
    max-width: 60px;

    overflow-wrap: break-word;
}

.rp .rp__size-m {
    min-width: 70px;
    max-width: 70px;

    overflow-wrap: break-word;
}

.rp .rp__size-l {
    min-width: 95px;
    max-width: 95px;

    overflow-wrap: break-word;
}

.rp .rp__size-xl {
    min-width: 150px;
    max-width: 150px;

    overflow-wrap: break-word;
}

.rp .rp__size-xxl {
    min-width: 322px;
    max-width: 322px;

    overflow-wrap: break-word;
}

.rp .rp__size-xxxl {
    min-width: 322px;
    max-width: 322px;

    overflow-wrap: break-word;
}

.rp .rp__size-xxxl {
    min-width: 530px;
    max-width: 530px;

    overflow-wrap: break-word;
}

.rp .rp__size-item-fist {
    border-left: 0;
}

.rp .rp__size-item-end {
    border-right: 0;
}

.rp_row-fix {
    position: -webkit-sticky;
    position: sticky;
    z-index: 10;
    /* overflow: hidden; */
}

tr th {
    font-size: 0.5rem;
    top: 70px;
}

td input {
    font-size: 1.2rem;
    width: 90%;
    border: 0;
    padding: 0;
    text-align: center;
    background: unset;
    margin: 0;
}

@media print {
    @page {
        size: landscape;
    }

    body * {
        visibility: hidden;
    }

    .rp_row-fix {
        position: unset;
        color: red;
    }

    .no-print,
    .no-print * {
        position: unset;
        color: red;
    }
}
